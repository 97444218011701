import React, { useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import Logo from "../../../assets/prospectus_logo_white.webp";
import Icon from "../../../assets/world.webp";
import { Link } from "react-scroll";
import './navbar.css';

const Navbar = () => {
  const [nav, setNav] = useState(false);

  const imgNavBar = {
    zIndex: 10,
    backgroundColor: '#055ec4',
};

  const handleNav = () => {
    setNav(!nav);
  };

  const closeNav = () => {
    setNav(false);
  };

  return (
    <div className="flex justify-between items-center px-5 text-white" style={imgNavBar}>
      <img className="w-[250px] mt-2 ml-1 pb-5" src={Logo} loading="eager"
            decoding="async"  alt="Prospectus NBN" />
      <hr className="linha-divisoria" />
      <div onClick={handleNav} className="block cursor-pointer">
        {nav ? <AiOutlineClose size={20} /> : <img src={Icon} alt={'Globe Icon'} style={{ width: '30px', height: '30px'}} />}
      </div>
      <div
        className={
          nav
            ? "fixed left-0 top-0 w-[300px] h-full border-r border-r-gray-900 ease-in-out duration-500 z-1000"
            : "fixed left-[-100%] z-1000"
        }
        style={nav ? imgNavBar : {}}
      >
        <img className="pt-5 w-[175px] mx-8" src={Logo} loading="eager"
            decoding="async"  alt="Imagem Espaço" />
        <ul className="uppercase p-4">
          <li className="p-4 border-b border-gray-600">
            <Link className='cursor-pointer z-1000' to="inicio" smooth={true} duration={500} onClick={closeNav}>
              INÍCIO
            </Link>
          </li>
          <li className="p-4 border-b border-gray-600">
            <Link className='cursor-pointer z-1000' to="3motores" smooth={true} duration={500} onClick={closeNav}>
              TRÊS MOTORES
            </Link>
          </li>
          <li className="p-4">
            <Link className='cursor-pointer z-1000' to="iniciarmissao" smooth={true} duration={500} onClick={closeNav}>
              INICIAR MISSÃO
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Navbar;
