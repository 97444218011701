import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';
import WebPage from './components/webPage/WebPage';
import BioLink from './components/bioLink/BioLink';
import QrCode from './components/qrCode/QrCode';

const Analytics = () => {
  const location = useLocation();

  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: location.pathname });
  }, [location]);

  return null;
};

function App() {
  useEffect(() => {
    ReactGA.initialize('G-H3JFZ5CLJ3');
  }, []);

  return (
    <Router>
      <Analytics />
      <Routes>
        <Route path="/" element={<WebPage />} />
        <Route path="/bio" element={<BioLink />} />
        <Route path="/qrcode" element={<QrCode />} />
        <Route path="*" element={<h1>404 Not Found</h1>} />
      </Routes>
    </Router>
  );
}

export default App;
